body {
  color: #fff;
  font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
  background-image: url(/home.jpg);
  background-color: #fff;
  background-blend-mode: soft-light;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

nav {
  height: 80px;
}

.mobile-nav {
  display: none;
}

/*header**/

header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
header ul {
  list-style: none;
  text-align: right;
  background-color: black;
}
header ul li {
  display: inline-block;
  height: 50px;
  text-align: center;
  font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
  line-height: 48px;
  padding: 0 20px 0 20px;
}
header ul li:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}
header ul li.current {
  background-color: black;
  color: white;
}
/**end-header**/

/**social**/

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
  float: left;
}
ul.social-network li {
  display: inline;
  margin: 0 5px;
}
.social-network a.icoYt:hover {
  background-color: #e52d27;
}
.social-network a.icoFacebook:hover {
  background-color: #3b5998;
}
.social-network a.icoGoogle:hover {
  background-color: #bd3518;
}
.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background-color: #1f2027;
}
.social-circle li i {
  margin: 0;
  line-height: 50px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 3.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.8s;
}
.social-circle i {
  color: #fff;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

/**end social**/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
  font-weight: bold;
}

.btn-dark.share {
  border-radius: 1.4rem;
  float: left;
  color: #fff;
  background-color: #6bb6ff !important;
  border-color: #6bb6ff !important;
}

.social-btn {
  margin-bottom: 5%;
  width: 100%;
}
.btn.no-outline {
  border-color: transparent !important;
}
.about-text {
  text-align: justify;
  font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
}

.about-head {
  text-align: left;
  color: #4b9bef;
}
.about.form-box {
  position: absolute;
  top: 20%;
  bottom: 0px;
  background-color: white;
  width: 100%;
}
.form-box {
  padding: 50px;
}

.logo {
  text-align: left;
  font-size: 20px;
}
.center {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
h1.home-text {
  color: #4b9bef;
  font-size: 3rem;
  letter-spacing: 12px;
  line-height: 100px;
}
h2.home-text {
  font-size: 1.5rem;
}

h1.text-2 {
  color: #4b9bef !important;
  font-size: 40px;
}
.background-form {
  background-image: url(https://images.unsplash.com/photo-1511898634545-c01af8a54dd5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2020&q=auto);
  height: 980px;
  width: 100%;
  background-position: center;
  background-color: #0b57e0;
  background-blend-mode: soft-light;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.background-form {
  padding: 75px;
  text-align: left;
}

.background-form h1 {
  font-size: 120px;
  letter-spacing: 12px;
  line-height: 100px;
}

.background-form h1 {
  font-size: 120px;
  letter-spacing: 12px;
  line-height: 100px;
}
.background-form .info-small {
  padding-top: 25px;
  font-size: 18px;
}
.background-form .info-second {
  bottom: 0%;
  position: absolute;
  font-size: 14px;
}
.background-form .logo {
  margin-top: 150px;
}

.form {
  color: #333;
  background: #ffffff;
  text-align: center;
}
.form.about-box {
  padding: 40px;
  text-align: left;
}

.form .info-second {
  margin-top: 25px;
  font-size: 12px;
  margin-bottom: 75px;
}
.form form {
  width: 80%;
  margin: 0px auto;
}
.form .form-control {
  padding: 20px;
  border-bottom: 1.5px solid #0099f7;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
  position: relative;
  height: 50px;
}
.form .form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #lightblue;
  box-shadow: none;
}

.inputarea {
  margin-bottom: 50px;
  position: relative;
}

small {
  position: absolute;
  left: 0px;
  bottom: 10px;
  font-size: 20px;
  color: #94a4ae;
  transition: 0.88s;
}
small:hover {
  bottom: 20px;
  transition: 0.88s;
}

.container {
  overflow: hidden;
  /**-webkit-box-shadow: 0px 0px 10px 0.9px #0c68be;
  -ms-box-shadow: 0px 0px 10px 0.9px #0c68be;
  box-shadow: 0px 0px 10px 0.9px #0c68be;**/
}

.form.left {
  padding-top: 20px;
}
.blue {
  color: #0b57e0;
  font-size: 14px;
}

.btn-transparent-black {
  float: right;
  font-size: 10px;
  font-weight: bold;
  background: white;
  border-radius: 5px;
  border-color: #6bb6ff;
  margin-bottom: 1%;
}

.btn-transparent-black:hover {
  background: #6bb6ff;
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
}
.btn-transparent-home.donate {
  background: transparent !important;
  padding-bottom: 0px;
}

.btn-transparent-home {
  font-size: 20px;
  padding: 5px 25px;
  font-weight: bold;
  color: white;
  background: transparent;
  border-radius: 5px;
  border-color: #6bb6ff;
}

.btn-transparent-home:hover {
  background: #6bb6ff;
  color: white;
  font-size: 20px;
  padding: 5px 25px;
  font-weight: bold;
  border-radius: 5px;
}

.btn-transparent-home:hover {
  background: #6bb6ff;
  color: white;
  font-size: 20px;
  padding: 5px 25px;
  font-weight: bold;
  border-radius: 5px;
}
.btn-transparent-home.speak {
  background: #6bb6ff;
  color: white;
  font-size: 20px;
  padding: 5px 25px;
  font-weight: bold;
  border-radius: 5px;
}
.btn-transparent {
  font-size: 20px;
  padding: 10px 50px;
  font-weight: bold;
  background: transparent;
  border-radius: 30px;
  border-color: #6bb6ff;
}

.btn-transparent:hover {
  background: #6bb6ff;
  color: white;
  font-size: 20px;
  padding: 10px 50px;
  font-weight: bold;
  border-radius: 30px;
}

.btn-green {
  background: #6bb6ff;
  color: white;
  font-size: 20px;
  padding: 10px 50px;
  font-weight: bold;
  border-radius: 30px;
}
.btn-green:hover {
  background: white;
  border-radius: 30px;
  border-color: #6bb6ff;
}

.btn:focus {
  box-shadow: none;
}

.subbtn {
  float: right;
}

.form-field {
  position: relative;
  text-align: left;
  padding-top: 25px;
  margin-bottom: 25px;
}

.form-label {
  width: 100%;
  position: absolute;
  top: 25px;
  bottom: 0px;
  left: 10px;
  text-align: left;
}

.spin-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.form .form-control {
  padding: 0px;
}
.select.form-label {
  position: initial !important;
}
.form select.form-control {
  padding: 0px;
}
textarea {
  resize: none;
}
@media only screen and (max-width: 1200px) {
  .background-form h1 {
    font-size: 80px;
    line-height: 73px;
  }
}
@media only screen and (max-width: 992px) {
  .background-form {
    height: 600px;
  }
  .background-form .logo {
    margin-top: 50px;
  }

  .form .info-second {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 992px) and (max-width: 768px) {
  .background-form {
    height: 500px;
    padding: 50px;
    background-position: top center;
  }
}
@media only screen and (min-width: 576px) {
  .form-box {
    padding: 25px;
  }

  .background-form h1 {
    font-size: 60px;
    line-height: 58px;
  }
}

@media only screen and (min-width: 768px) {
  .about.form-box {
    height: fit-content;
  }
}

/* Portrait ipad pro */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .about.form-box {
    height: auto;
  }
}
@media only screen and (max-width: 576px) {
  .navbar {
    padding: 0.5rem 0.1rem;
  }
  .btn-transparent-home.speak {
    font-size: 15px;
  }
  body {
    background-size: unset;
  }
  .mobile-nav {
    display: block;
    margin: auto;
  }
  .btn-transparent-home.top-btn {
    padding: 0px 15px;
    font-size: 10px;
  }
  .btn-transparent-home.top-btn:hover {
    font-size: 10px;
    padding: 0px 15px;
  }

  .form {
    height: 100%;
  }
  .background-form {
    display: none;
  }

  .form-box {
    padding: 10px;
  }

  .subbtn {
    float: none;
  }
  .slide-to-back {
    margin-bottom: 5%;
  }

  .about-head {
    text-align: left;
  }

  h1.home-text {
    font-size: 2rem;
    letter-spacing: 4.6px;
    line-height: 37.5px;
  }

  h2.home-text {
    font-size: 1rem;
  }

  .about.form-box {
    bottom: auto;
  }

  .center.h2{
    font-size: 1.5rem;
  }
}

.slide-to-form-top {
  text-align: left;
  float: left;
}
.slide-to-form {
  cursor: pointer;
  text-align: right;
  float: right;
  font-weight: bold;
}

/* ANIMATIONS */
.slide-out {
  transform: translateX(0%);
  transition: all 100ms ease;
}

.slide-in {
  transform: translateY(0%);
  transition: all 100ms ease;
}
